<template>
  <b-container v-if="module">
    <b-row align-v="center">
      <b-col class="mb-1">
        <b-link
          to="/farm/hve"
          class="text-secondary"
        >
          <feather-icon icon="ArrowLeftIcon" />
          Retour à la synthèse HVE
        </b-link>
      </b-col>
    </b-row>
    <b-row
      align-v="center"
      class="mb-1"
    >
      <b-col
        cols="12"
        class="d-flex align-items-center mb-1"
      >
        <h2 class="m-0">
          {{ module.name }}
        </h2>
        <b-dropdown
          v-if="hveCultures.length > 1"
          class="custom-dropdown ml-1"
          variant="outline-primary"
          :text="localize(currentHveCulture)"
        >
          <b-dropdown-item
            v-for="(culture, index) in hveCultures"
            :key="index"
            @click="$store.commit('verticalMenu/SET_GES_CULTURE', culture)"
          >
            {{ localize(culture) }}
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <b-row
          v-for="indicator in indicators"
          :key="indicator.id"
        >
          <b-col>
            <IndicatorCard
              :indicator="indicator"
              :is-active="currentIndicator.id === indicator.id"
              @click.native="currentIndicator = indicator"
            />
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="6">
        <IndicatorChart
          style="position: sticky; top: 95px;"
          :indicator="currentIndicator"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BContainer, BRow, BCol, BLink, BDropdown, BDropdownItem
} from "bootstrap-vue";
import IndicatorCard from "@/components/farm/IndicatorCard.vue";
import IndicatorChart from "@/components/farm/IndicatorChart.vue";
import { mapGetters, mapState } from "vuex";
import { getUserData } from "@/auth/utils";
import localize from "@/utils/localize";

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BLink,
    IndicatorCard,
    IndicatorChart,
    BDropdown,
    BDropdownItem
  },
  data() {
    return {
      userData: getUserData(),
      currentIndicator: {}
    };
  },
  computed: {
    ...mapGetters("farm", ["modulesA", "getModuleABySlug"]),
    ...mapState("verticalMenu", ["currentHveCulture", "hveCultures"]),
    ...mapState("farm", { farmHveAudit: "hveAudit" }),
    module() {
      const pathModuleSlug = this.$route.path.split("/").pop();

      return this.getModuleABySlug(pathModuleSlug);
    },
    indicators() {
      return this.module.indicators;
    }
  },
  created() {
    [this.currentIndicator] = this.indicators;
  },
  methods: {
    localize
  }
};
</script>

<template>
  <b-card
    v-if="isShown"
    title="Nombre d’exploitations par valeur moyenne"
  >
    <e-charts
      v-if="indicator.measure.value_type === 'number'"
      style="width: auto;"
      :options="barChartData"
      theme="theme-color"
    />
    <e-charts
      v-if="indicator.measure.value_type === 'boolean'"
      style="width: auto;"
      :options="pieChartData"
      theme="theme-color"
    />
  </b-card>
</template>

<script>
import { getUserData } from "@/auth/utils";
import ECharts from "vue-echarts";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/chart/bar";
import "echarts/lib/chart/pie";
import theme from "@core/components/charts/echart/theme.json";
import { mapState } from "vuex";
import { BCard } from "bootstrap-vue";

ECharts.registerTheme("theme-color", theme);

export default {
  name: "IndicatorChart",
  components: {
    ECharts,
    BCard
  },
  props: {
    indicator: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      userData: getUserData(),
      measures: [],
      barChartData: {
        grid: {
          top: 12,
          right: 0,
          bottom: 48
        },
        legend: {
          left: 0
        },
        yAxis: [
          {
            type: "value",
            minInterval: 1,
            splitLine: { show: false },
            axisLabel: {
              formatter: "{value}"
            }
          }
        ]
      },
      pieChartData: {
        tooltip: {
          trigger: "item",
          formatter: "{b} : {c} ({d}%)"
        },
        legend: {
          orient: "vertical",
          left: "left",
          data: ["Oui", "Non", "NC"]
        },
        series: []
      },
      isShown: false
    };
  },
  computed: {
    ...mapState("verticalMenu", ["currentPeriod", "currentHveCulture", "currentCooperative"]),
    ...mapState({
      farmHveAudit: state => state.farm.hveAudit,
      coopHveAudit: state => state.cooperative.hveAudit
    }),
    farmCoopId() {
      return this.coopHveAudit?.cooperative.id;
    },
    farmId() {
      return this.farmHveAudit.farm.id;
    },
    indicatorsValues() {
      return this.measures
        .filter(measure => !["boolean", "string"].includes(measure.value_type))
        .map(measure => measure.raw_value);
    },
    indicatorsBooleanValues() {
      return this.measures
        .filter(measure => measure.value_type === "boolean")
        .map(measure => measure.raw_value);
    },
    minIndicatorsValues() {
      return Math.floor(Math.min(...this.indicatorsValues));
    },
    maxIndicatorsValues() {
      return Math.ceil(Math.max(...this.indicatorsValues));
    },
    isAllSameMeasures() {
      return this.indicatorsValues.every(value => value === this.indicatorsValues[0]);
    }
  },
  watch: {
    indicator() {
      this.getMeasures();
    },
    currentPeriod() {
      this.getMeasures();
    },
    currentHveCulture() {
      this.getMeasures();
    }
  },
  created() {
    this.getMeasures();
  },
  methods: {
    formatBarChartData(intervalsCount) {
      const category = []; /* Nom des catégories de xAxis echarts 30/32% 32/34% */
      const series = []; /* Données echarts de type [[x, y],[x2, y2]], x = l'index de la category, y = la valeur en ordonnée */

      const unit = this.indicator.unit === "%" ? "%" : "";

      // toutes les fermes ont la même valeur ou il y a une seule ferme qui a une valeur
      if (this.isAllSameMeasures || this.indicatorsValues.length <= 1) {
        this.isShown = false;
      } else {
        /* Calcule l'écart entre chaque catégorie de valeur */
        const intervalsWidth = (this.maxIndicatorsValues - this.minIndicatorsValues) / intervalsCount;

        for (let i = 0; i < intervalsCount; i += 1) {
          /* Détermine le minimum et le maximum pour chaque category */
          const lowerBound = Number((this.minIndicatorsValues + intervalsWidth * i).toFixed(1));
          const upperBound = Number((lowerBound + intervalsWidth).toFixed(1));

          // on récupère les valeurs qui correspondent à l'interval
          const currentValues = this.indicatorsValues.filter(value => {
            const isMeasureMax = Number(value) === upperBound && i === intervalsCount - 1;
            const isMeasureContained = value >= lowerBound && value < upperBound;
            return isMeasureMax || isMeasureContained;
          });

          const isCurrentFarmMeasure = currentValues.includes(this.measures.find(m => m.farm_id === this.farmId)?.raw_value);
          // on calcule la moyenne des valeurs de l'interval
          const averageMeasure = currentValues.length === 0 ? 0 : currentValues.reduce((acc, measure) => acc + parseFloat(measure), 0) / currentValues.length;

          category.push(Math.round(averageMeasure * 100) / 100);

          /* Construis le tableau attendu par la series echarts et assigne une couleur différente pour la ferme actuelle. */
          if (currentValues.length > 0) {
            series.push({
              value: [i, currentValues.length],
              itemStyle: {
                color: isCurrentFarmMeasure ? "#28DAC6" : "#9aece3"
              }
            });
          }
        }

        /* Construis l'option attendu par echarts */
        this.barChartData = {
          ...this.barChartData,
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow"
            },
            formatter: params => (
              `${params[0].name}${unit}<br/><span style='display:inline-block;width:10px;height:10px;background-color:${params[0].color};margin-right:5px;'></span>${params[0].value[1]}`
            )
          },
          xAxis: [
            {
              type: "category",
              data: category, // Ajout des données formatées.
              axisLabel: {
                interval: 0,
                rotate: 30,
                formatter(value, index) {
                  return value !== "0" || !index ? `${value}${unit}` : "";
                }
              }
            }
          ],
          series: [
            {
              type: "bar",
              barMaxWidth: "20%",
              barMinWidth: "20px",
              data: series, // Ajout des données formatées.
              itemStyle: {
                barBorderRadius: [15, 15, 0, 0]
              }
            }
          ]
        };

        this.isShown = true;
      }
    },
    formatPieChartData() {
      const measures = this.measures.map(measure => measure.raw_value);
      if (measures.length <= 1) {
        this.isShown = false;
      }
      this.pieChartData = {
        ...this.pieChartData,
        series: [
          {
            type: "pie",
            stillShowZeroSum: false,
            label: {
              show: false
            },
            radius: "55%",
            center: ["50%", "60%"],
            data: [
              { value: measures.filter(value => value === "true").length, name: "Oui" },
              { value: measures.filter(value => value === "false").length, name: "Non" },
              { value: measures.filter(value => value === "").length, name: "NC" }
            ],
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              }
            }
          }
        ]
      };
      this.isShown = true;
    },
    getMeasures() {
      if (this.farmCoopId) {
        this.$http.getMeasures({
          "cooperative_ids[]": this.farmCoopId,
          indicator_id: this.indicator.id,
          period: this.currentPeriod,
          "cultures[]": this.currentHveCulture
        })
          .then(({ data }) => {
            this.measures = data;
            if (this.indicator.measure.value_type === "number") {
              this.formatBarChartData(10);
            }
            if (this.indicator.measure.value_type === "boolean") {
              this.formatPieChartData();
            }
          });
      }
    }
  }
};
</script>

<template>
  <b-card
    :class="{active: isActive}"
    style="cursor: pointer;"
  >
    <b-row align-v="center">
      <b-col cols="2">
        <feather-icon
          v-if="indicator.measure.passed"
          icon="CheckCircleIcon"
          size="60"
          class="text-success bg-light-success rounded-circle p-1"
        />
        <feather-icon
          v-else
          icon="XIcon"
          size="60"
          class="text-warning bg-light-warning rounded-circle p-1"
        />
      </b-col>
      <b-col>
        <b-row>
          <b-col class="text-secondary">
            <h3>{{ indicator.name }}</h3>
            <template v-if="updated_at.length > 0">
              {{ updated_at }}
            </template>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        cols="auto"
        class="h1 text-right"
        style="max-width: 140px;"
      >
        {{ value }}
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol } from "bootstrap-vue";
import dayjs from "dayjs";

export default {
  components: {
    BCard,
    BRow,
    BCol
  },
  props: {
    indicator: {
      type: Object,
      default: () => ({})
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    updated_at() {
      if (this.indicator.measure.updated_at) {
        return `Mis à jour le ${dayjs(this.indicator.measure.updated_at).format("DD/MM/YYYY")}`;
      }
      return "";
    },
    value() {
      if (this.indicator.measure.raw_value !== null) {
        return this.indicator.measure.pretty_value_with_unit;
      }

      return "N.C.";
    }
  }
};
</script>

<style lang="scss" scoped>
  .active {
    box-shadow: 0 0 3px 2px #28dac6;
  }
</style>
